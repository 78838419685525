import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import type { ActiveRoute, RouteState } from "@/__main__/router.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import ApexFixtures from "@/game-apex/apex-fixtures.json";

export default function apexRedirectOverlayLegendSelect(
  _route?: ActiveRoute,
  searchParams?: URLSearchParams,
  _state?: RouteState,
) {
  if (!IS_APP && !IS_NODE && !searchParams.toString()) {
    updateRoute(
      "/apex/overlay/legend-select",
      `profileId=${ApexFixtures.profileId}&tab=legends&mode=ALL`,
      { isRedirect: true, isUpdate: false },
    );
    return;
  }

  return false;
}
